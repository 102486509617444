import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 mt-[8rem] text-gray-800">
      <h1 className="text-3xl font-bold text-center mb-6">DATA PROTECTION POLICY - TEKNOKLEEN NIGERIA LIMITED</h1>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">1. INTRODUCTION</h2>
        <p>TEKNOKLEEN NIGERIA LIMITED ("the Company") is committed to ensuring the privacy and protection of personal data collected from clients, employees, and third parties. This policy is established in accordance with the Nigeria Data Protection Regulation (NDPR) and other relevant laws.</p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">2. SCOPE</h2>
        <p>This policy applies to all employees, contractors, and third-party service providers who handle personal data on behalf of the Company. It covers all personal data processed in any form, including electronic, paper-based, and cloud-stored data.</p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">3. LEGAL BASIS FOR DATA PROCESSING</h2>
        <ul className="list-disc ml-6">
          <li>Consent from data subjects</li>
          <li>Contractual necessity</li>
          <li>Legal obligations</li>
          <li>Legitimate business interests</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">4. TYPES OF PERSONAL DATA COLLECTED</h2>
        <ul className="list-disc ml-6">
          <li><strong>Clients:</strong> Name, address, phone number, email, and payment details</li>
          <li><strong>Employees:</strong> Name, contact details, bank details, tax information, and emergency contacts</li>
          <li><strong>Vendors:</strong> Business name, contact details, and payment information</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">5. PURPOSES OF DATA COLLECTION</h2>
        <ul className="list-disc ml-6">
          <li>Service delivery and customer support</li>
          <li>Employee management and payroll processing</li>
          <li>Contract execution with vendors</li>
          <li>Compliance with regulatory requirements</li>
          <li>Marketing and promotional activities (subject to consent)</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">6. DATA STORAGE AND SECURITY</h2>
        <ul className="list-disc ml-6">
          <li>Password protection and encryption for electronic data</li>
          <li>Restricted access to sensitive data</li>
          <li>Regular security audits and staff training</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">7. DATA RETENTION POLICY</h2>
        <p>Personal data shall only be retained for as long as necessary to fulfill the purposes for which it was collected. After the retention period expires, data will be securely deleted or anonymized.</p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">8. DATA SUBJECT RIGHTS</h2>
        <ul className="list-disc ml-6">
          <li>Right to access their personal data</li>
          <li>Right to rectify inaccurate data</li>
          <li>Right to request deletion of their data</li>
          <li>Right to restrict processing</li>
          <li>Right to data portability</li>
          <li>Right to withdraw consent</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">9. THIRD-PARTY DATA SHARING</h2>
        <p>The Company may share personal data with third-party service providers, regulatory bodies, or business partners, ensuring that appropriate data protection agreements are in place.</p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">10. DATA BREACH MANAGEMENT</h2>
        <ul className="list-disc ml-6">
          <li>Assess the impact and contain the breach</li>
          <li>Notify affected individuals where necessary</li>
          <li>Report to regulatory authorities as required under NDPR</li>
          <li>Implement corrective measures to prevent future occurrences</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">11. COMPLIANCE AND ENFORCEMENT</h2>
        <p>All employees and stakeholders must comply with this policy. Non-compliance may result in disciplinary action, including termination of employment or legal consequences.</p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">12. POLICY REVIEW</h2>
        <p>This policy will be reviewed annually or as required to align with legal and regulatory changes.</p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold">13. CONTACT INFORMATION</h2>
        <p>For inquiries or concerns regarding data protection, contact the Data Protection Officer (DPO) at:</p>
        <p><strong>TEKNOKLEEN NIGERIA LIMITED</strong></p>
        <p>PLOT 2 ORIWU STREET, LEKKI PHASE 1, LAGOS</p>
        <p>Email: <a href="mailto:teknokleen@teknokleen.com" className="text-blue-600">teknokleen@teknokleen.com</a></p>
        <p>Telephone: <a href="tel:+2347068571371" className="text-blue-600">2347068571371</a></p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
